.tooltip {
    position: absolute;
    display: inline-block;        
    width: 155px;
    opacity: 1 !important;
  }
  
  .tooltip .tooltiptext {
    width: 100px;
    background-color: #222;
    color: #fff;
    opacity: 0.8;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;    
    margin-left: -60px;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #222 transparent transparent transparent;
  }
.poupup-size{
    overflow: auto; 
    position: absolute;
    width: 50%;
    height: 30%;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    background-color:white;
    transform: translate(-50%, -50%);
}
    

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  

.floating-menu {
  top:90px;
  z-index: 999;
	position: fixed;
  right: 35px;
	height: 42px;
  text-align: right;
	color:white !important;
}

.floating-menu.open {margin-bottom: 30px;}

.floating-menu-item label{
	display: inline-block;
	margin-right: 8px;
	padding: 4px 10px;
	border-radius: 12px;
	background:#222;
	opacity: 0;
	transition: opacity 250ms ease-in-out;
}

.floating-menu.open .floating-menu-item label {opacity: 0.4;}

.floating-menu-icon{
	display: inline-block;
	width: 50px;
	height: 50px;
	line-height: 50px;
	vertical-align: middle;
	background: #2C995B !important;
	border-radius: 50%;
	border: none;
	box-shadow: 0 2px 4px rgba(0,0,0,.2);
  text-align: center;
  color: white;
}

.floating-menu-icon .material-icons {
	font-size: 32px;
	color: #fff;
	vertical-align: middle;
}

.btn-default-export-float{
  color: white ;
  right: 70px ;
  border-radius: 50% ;
  font-size: 12px ;
  z-index: 999 ;
  width: 32px ;
  height: 32px;
  line-height: 18px ;
  background: #554537 ;
  border: 0;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

.btn-default-export-float:hover{
  background: #5e4937 !important;
  color: white !important; 
}

.btn-default-import-float{
  position: fixed;
  color: white !important;
  right: 75px;
  top: 168px;
  border-radius: 50% !important;    
  font-size:12px;
  z-index: 999;
  width: 32px;
  height: 32px;
  background: rgb(34, 127, 0) !important;
  border: 0;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

.btn-default-import-float:hover{
  background: rgb(40, 148, 1) !important;
  color: white !important; 
}

.btn-default-add-float{
  position: fixed;
  right: 84px;
  top: 120px;
  border-radius: 50% !important;    
  font-size:10px;
  z-index: 999;
  width: 32px;
  height: 32px;
  line-height:21px;
  background: #2AAF65;
  border: 0;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

.btn-default-add-float:hover{background: #2AAF65 !important;}


@media screen and (max-device-width: 640px){
  .btn-default-add-float{
    bottom: 90px;
          top: auto;
      right: 10px;
  }
  .btn-default-export-float{
      bottom: 75px;
      top: auto;
      right: 10px;
  }
  .btn-default-import-float{
    bottom: 130px;
    top: auto;
    right: 10px
}
.floating-menu{
  bottom: 15px;
  top: auto;
  right: 10px
}

}
