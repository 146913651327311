.avatar-user {
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 50%;
}
.labels {
  text-align: left !important;
}
.logo-agri {
  width: 100%;
}
.logo-mini {
  width: 75%;
}
.form-center {
  margin-top: 100px;
}
.pass-strength {
  font-size: 12px !important;
}
.poupup-confirm-email {
  overflow: auto;
  position: absolute;
  width: 50%;
  height: auto;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  background-color: #ffffff;
  transform: translate(-50%, -50%);
}
.poupup-confirm-email .checked-gif {
  width: 250px;
  height: 200px;
  justify-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 200px) and (max-width: 500px) {
  .center {
    margin-top: 15px !important;
  }
  .form-center {
    padding: 10px 0px 0px 10px;
    margin-top: 40px;
  }
}
