.clear_map {
  top: 5px;
  left: 55%;
  position: absolute;
  margin: 0 auto;
}

.collapsed-button-map {
  z-index: 999;
  position: absolute;
  top: 10px;
  right: 4px;
  /* max-width: 290px; */
  /* width: 311px; */
}

.collapsed-content {
  background: #efefef;
  color: #000;
  z-index: 999;
  position: absolute;
  top: 46px;
  right: 9px;
  /* max-width: 285px;
  width: 240px; */
  height: 386px;
  max-height: 386px;
  border-radius: 4px;
}

.table-responsive {
  max-height: 300px !important;
}

ul.bar_tabs > li a {
  color: #3a3636;
}

@media screen and (max-device-width: 640px) {
  .collapsed-button-map {
    width: 100px;
    z-index: 999;
    margin-top: -5px;
  }

  ul.bar_tabs > li {
    border: 1px solid #e6e9ed;
    color: #333 !important;
    margin-top: -17px;
    margin-left: 4px;
    background: #fff;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
  }
  /* .collapsed-content {
    z-index: 999;
    height: 375px;
    max-height: 375px;
  } */
  .clear_map {
    margin-top: -38px !important;
    margin-left: 6px;
    position: absolute;
    width: 102px !important;
  }
}
