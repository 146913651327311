.appoiments-container li > a {
  color: #7b8ea2 !important;
}

.appoiments-container label {
  color: #7b8ea2 !important;
}
.table-row-color td a {
  color: #7b8ea2 !important;
}

.table-row-color tr > td {
  color: #7b8ea2 !important;
}

.table-row-color td a:hover {
  color: #a1b6cc !important;
}

.loading-spinner {
  margin-top: 50px;
  margin-left: 50%;
  min-height: 200px;
}
.collapse-suport {
  border: 1px solid #e6e9ed;
  border-radius: 4px;
}
.row-collpse-responsive {
  width: 100% !important;
  display: flex !important;
}

.col-collapse {
  width: 23%;
  margin-right: 18px;

  -webkit-align-items: center;
  align-items: center;
}

.col-collapse-suply {
  /* width: 16%; */
  margin-right: 18px;

  -webkit-align-items: center;
  align-items: center;
}
.col-6-responsive {
  margin-left: 150px;
  width: 39%;
}

.responsive-inline-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.input-collapse {
  height: 37px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: 0;
}
.tab-children {
  float: left;
  margin-bottom: -1px;
  position: relative;
  display: block;
}

.content-tabs > a {
  padding: 13px 28px 2px;

  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid #a3bbbe;
  border-radius: 4px 4px 0 0;
  border-bottom-color: transparent;
  color: #a3bbbe;
  opacity: 0.2s;
}

.content-tabs > a:hover {
  color: #8791a2;
}

.title-appointments {
  color: #8791a2;
}
