.span-item-small {
    background: #dddddd;
    height: 33px;
    text-align: center;
    border-radius: 18px;
    margin-top: 10px;
}


.step-second-select{
    width: 75%;
    margin-left: 10%;
    padding-right: 15px;

}