body {
  background: #fff;
}
.no-float {
  float: none;
}
.map-container {
  width: 100%;
  height: 100%;
  min-height: 440px;
  position: relative;
}
.map-container .map-search {
  z-index: 99999;
  position: absolute;
  top: 10px;
  right: 49px;
  max-width: 300px;
}
.map-container .map-search .btn-search {
  height: 40px;
  width: 100%;
  border-right: 1px solid #dddddd;
  font-size: 22px;
  background-color: #fff;
  border: 0;
  color: #000000;
  margin: 0;
  border-radius: 0;
}
.map-container .map-search .btn-search.active:hover {
  background: #fff;
}
.map-container .map-search .btn-search:hover {
  background: #dddddd;
}
.map-container .map-search .container-search .list-group-item:first-child {
  text-align: center;
}
.map-container .map-search .container-search .list-group-item .fa-undo {
  margin-right: 5px;
}
.map-container .map-search .container-search .list-group-item h5 > strong {
  color: #000000;
}
.map-container .map-search .minimize-button {
  width: 50px;
  height: 40px;
}
.map-container .map-search .container-search {
  background: #fff;
  margin-top: -3px;
  max-height: 395px;
  overflow: scroll;
  border: 1px solid #dddddd;
  padding: 10px;
}
.map-container .map-search .container-search ul li {
  cursor: pointer;
}

.map-container .map-search .container-search ul {
  margin-top: 10px;
}

.d-inline {
  display: inline;
}
.ico-alert {
  font-size: 17px;
  margin-right: 8px;
}

.inline-block {
  display: inline-block;
}
.bg-success {
  background: #2daf65 !important;
}
.bg-warning {
  background: #f1f14f !important;
}
.bg-finished {
  background: #4d4b4b !important;
}
.mt-5 {
  margin-top: 5px;
}
.operation_item_list {
  color: #7b8ea2;
  float: left;
  width: 100%;
}
.operation_item_list:hover {
  color: #a1b6cc;
}
.table_operation_list td,
th {
  padding: 8px 2px 10px 0 !important;
}
.table_operation_list .indicator-collumn {
  padding-top: 14px !important;
}

.w-90 {
  width: 90px;
}
.mr-30 {
  margin-right: 30px;
}

.alert.fade {
  opacity: 1 !important;
}

.operation_register_content .MuiInputBase-root:before,
.operation_register_content .MuiInput-underline:after {
  content: none !important;
}

@media only screen and (max-width: 480px) {
  .table_operation_list .status-indicator {
    margin-left: 40px;
    margin-top: 8px;
  }
  .table_operation_list th {
    text-align: center;
  }
  .operation-detail-container .col-filter {
    width: 100% !important;
    padding: 0 !important;
    margin-top: 5px;
  }
  .operation-detail-container .op-search .form-control {
    width: 100%;
    line-height: 40px;
    height: 40px;
  }
}

.mt-0 {
  margin-top: 0;
}

/* Operation Detail */
.operation-detail-container .no-padding {
  padding: 0;
}
.operation-detail-container .flex-box {
  display: inline-flex;
}

.operation-detail-container .PrivateNotchedOutline-root-69 {
  border-radius: 0 !important;
  top: -7px !important;
}
.operation-detail-container .op-search .lh-25 {
  line-height: 25px;
}

.operation-detail-container .tooltip-dinamic-filters {
  width: 347px;
  margin-right: 10px;
}
.operation-detail-container .w-40 {
  width: 40px;
}
.operation-detail-container .col-filter {
  width: 29%;
  padding: 0;
}
.operation-detail-container .op-search .no-padding-right {
  padding-right: 0;
}
.operation-detail-container .op-search .no-padding-left {
  padding-left: 0;
}

.operation-detail-container .op-search .form-control {
  height: 40px;
}

.btn-default-export {
  position: fixed;
  color: white !important;
  right: 35px;
  top: 125px;
  border-radius: 500px !important;
  font-size: 16px;
  z-index: 999;
  width: 50px;
  height: 50px;
  line-height: 37px;
  background: #554537 !important;
  border: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.btn-default-export:hover {
  background: #6c5847 !important;
  color: white !important;
}
.btn-default-add {
  position: fixed;
  right: 35px;
  top: 69px;
  border-radius: 500px !important;
  font-size: 16px;
  z-index: 999;
  width: 50px;
  height: 50px;
  line-height: 37px;
  background: #2aaf65;
  border: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.btn-default-add:hover {
  background: #2aaf65 !important;
}

.btn-primary {
  background: #2c995b !important;
  border: 0 !important;
}

.btn-primary:hover {
  background: #2aaf65 !important;
  border: 0 !important;
}

@media screen and (max-device-width: 640px) {
  .btn-default-add {
    bottom: 20px;
    top: auto;
    right: 10px;
  }
  .btn-default-export {
    bottom: 75px;
    top: auto;
    right: 10px;
  }
  .x_content .tab-pane svg {
    padding: 0 !important;
  }
  .select-container {
    width: 100% !important;
    float: none !important;
  }
}

.select-container {
  width: 25%;
  float: left;
  margin-right: 20px;
}

.circle-red{
  height: 10px;
  width: 10px;
  background-color: #ff1a1a;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
}
.circle-green{
  height: 10px;
  width: 10px;
  background-color: #2aaf65;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
}

.btn-export-extra {
  top: 70px;
  background-color: green !important;  
}
.btn-export-extra:hover {
  background: green !important;
  color: white !important;
}