.row-steps {
  display: -ms-flexbox !important ;
  display: flex !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  margin-right: -15px !important;
  margin-left: -15px !important;
}

.step-first-selects {
  width: 31%;
  padding-right: 15px;
}

.step-first-btn {
  width: 4%;
  margin-top: 14px;
}
.btn-in-row {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.span-item {
  background: #dddddd;
  height: 33px;
  text-align: center;
  border-radius: 18px;
  margin-top: 10px;
}
