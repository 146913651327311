.icon {
    font-size: 1.5em;
}

.image {
    height: 85% !important;
}

.site_title {
    text-overflow: ellipsis !important;
    font-weight: 400 !important;
    font-size: 22px !important;
    width: 100% !important;
    line-height: 59px !important;
    display: block !important;
    height: 55px !important;
    padding-left: 10px !important;
    color: rgb(236, 240, 241) !important;
    overflow: hidden !important;
}

.span {
    display: inline-block;
    width: 0.3vw
}

.img-circle {
    width: 57px !important;
    height: 57px !important;
}

.MuiOutlinedInput-input {
    padding: 12px 12px !important
}

.nav-sm ul.nav.child_menu {
    left: 97% !important;
}
.initial-loading {
    width: 100%;
    height: 100%;
    padding-top: 10%;
    text-align: center;
}
.initial-loading img{ 
    vertical-align: middle;
    text-align: center;
}
.logo-loading {
    height: 150px;
}

.list_farms{
    min-height: 87px !important;
    max-height: 457px !important;
    overflow: scroll !important;     
    overflow-x: hidden !important;
}
