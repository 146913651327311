body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  scrollbar-color: #e2e2e2 #2c995b;
}

::-webkit-scrollbar {
  width: 10px;
}

::selection {
  color: #fff;
  background: #3eaf5c !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.operation-list-container .pull-left a {
  color: #c5c7cb !important;
}

.operation-list-container .pull-left a:hover {
  color: #23527c !important;
}

.operation-list-container td a {
  color: #7b8ea2 !important;
}

.operation-list-container td a:hover {
  color: #a1b6cc !important;
}

.operation-list-container td,
h2,
th {
  color: #7b8ea2 !important;
}
td,
th {
  color: #7b8ea2 !important;
}
.operation-detail-container {
  color: rgb(115, 135, 156);
}

.operation-detail-container h1 {
  color: rgb(115, 135, 156);
}

.operation-detail-container .pull-right {
  color: rgb(115, 135, 156);
}

#operation_update_container {
  color: rgb(115, 135, 156);
}

#menu_toggle a:hover {
  color: #ecf0f1;
}
#machine_list_container h4 {
  color: rgb(115, 135, 156);
}
#machine_list_container .btn-group a {
  color: rgb(115, 135, 156) !important;
}

#machine_list_container h4 {
  color: rgb(115, 135, 156) !important;
}

#machine_list_container {
  color: rgb(115, 135, 156);
}

#machine_list_container .btn-group a:hover {
  color: #333;
}

#machine_form_container {
  color: rgb(115, 135, 156);
}

#machine_daily_report {
  color: rgb(115, 135, 156);
}

#machine_daily_report h1 {
  color: rgb(115, 135, 156);
}

.pull-right {
  color: rgb(115, 135, 156);
}

#implement_list_container td {
  color: rgb(115, 135, 156);
}

#implement_list_container {
  color: rgb(115, 135, 156);
}

#implement_list_container .table a {
  color: rgb(115, 135, 156);
}

#implement_list_container .table a:hover {
  color: #333;
}

#implement_form_container {
  color: rgb(115, 135, 156);
}

#operator_list_container {
  color: rgb(115, 135, 156);
}

#operator_list_container h4 {
  color: rgb(115, 135, 156);
}

#operator_list_container .pull-right a {
  color: rgb(115, 135, 156) !important;
}

#operator_list_container .pull-right a:hover {
  color: rgb(115, 135, 156);
}

#operator_create_container {
  color: rgb(115, 135, 156);
}

#landplot_form_container {
  color: rgb(115, 135, 156);
}
#report_fleet_container a {
  color: rgb(115, 135, 156);
}

#report_fleet_container h2,
a {
  color: rgb(115, 135, 156);
}

.months {
  color: rgb(115, 135, 156);
}
.months:hover {
  color: #23527c;
}

#report_fleet_container {
  color: rgb(115, 135, 156);
}

#report_activity_container {
  color: rgb(115, 135, 156);
}

#report_activity_container h2,
small,
a {
  color: rgb(115, 135, 156);
}

.path-report-box {
  color: rgb(115, 135, 156);
}

.path-report-box h1 small {
  color: rgb(115, 135, 156) !important;
}

#propriedades_create_container {
  color: rgb(115, 135, 156);
}

#profile_update_container {
  color: rgb(115, 135, 156);
}

#admin_list_container {
  color: rgb(115, 135, 156);
}

#admin_list_container h4 {
  color: rgb(115, 135, 156);
}

#admin_list_container .pull-right a {
  color: rgb(115, 135, 156);
}

#admin_list_container .pull-right a:hover {
  color: rgb(115, 135, 156);
}

#profile_reset_password {
  color: rgb(115, 135, 156);
}

#admin_form_container {
  color: rgb(115, 135, 156);
}

#signup_profile_container {
  color: rgb(115, 135, 156);
}

#signup_profile_container h1 {
  color: rgb(115, 135, 156);
}

#login_container {
  color: rgb(115, 135, 156);
}

#login_container h1 {
  color: rgb(115, 135, 156);
}

#login_container a {
  color: rgb(115, 135, 156);
}

#login_container a:hover {
  color: #23527c;
}
a {
  color: #ecf0f1;
}

.profile_info h2 {
  color: #ecf0f1 !important;
}

.searchForm .operation_register_content {
  color: rgb(115, 135, 156) !important;
}

.operation_register_content {
  color: rgb(115, 135, 156) !important;
}

.btn-default {
  color: #333 !important;
  background-color: #ecf0f1;
  border-color: #ccc;
  border-top-color: rgb(204, 204, 204);
  border-right-color: rgb(204, 204, 204);
  border-bottom-color: rgb(204, 204, 204);
  border-left-color: rgb(204, 204, 204);
}
.toggle a:hover {
  color: #ecf0f1;
}

.toggle-subtitle {
  margin-left: 10px;
  font-size: 12px;
  position: absolute;
  margin-top: 3px;
}

.btnSearch {
  height: 33px;
}

.toggle-component {
  background: #dbdbdb;
  padding: 14px;
}

.glyphicon-filter i {
  color: gray;
  margin-left: 5px;
}

.glyphicon-filter {
  color: #2daf65;
  margin-left: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.operation_register_content {
  margin: 20px;
}

.operation_register_content .first-op-step-container {
  position: relative;
  top: 10px;
  margin-left: 50%;
  left: -10%;
}

.operation_register_content .no-position {
  position: unset !important;
}
.searchForm label {
  color: rgb(115, 135, 156);
}
input[type="radio"] {
  cursor: pointer;
}
.check {
  width: 17px;
  height: 17px;
  margin-right: 7px;
  position: relative;
  border-radius: 50%;
  transition: transform 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.btn-kpi-add {
  width: 100%;
  border: none;
  background: #2c995b;
  color: rgb(255, 249, 249);

  transition: 0.2s;
}
.btn-kpi-add:hover {
  width: 100%;
  border: none;
  background: #45bb78;
  color: #fff;
}
.machine-link-detail {
  list-style: none;

  text-decoration: none;

  color: #333;
}
.machine-link-detail:hover {
  color: #333;
}

.list-machines-with-link {
  min-height: 87px !important;
  max-height: 250px !important;
  overflow: scroll !important;
  overflow-x: hidden !important;
}

.list-operations-with-link {
  min-height: 87px !important;
  max-height: 250px !important;
  overflow: scroll !important;
  overflow-x: hidden !important;
}

.table-machines-idle{
  min-height: 87px !important;
  max-height: 250px !important;
  overflow: scroll !important;
  overflow-x: hidden !important;
}

@media (min-width: 1200px) {
  .modal-list-machines-web {
    position: absolute;
    top: -61px;
    right: 270px;
    width: 24%;
  }
  .modal-list-operations-web {
    width: 24%;
    z-index: 999;
    position: absolute;
    top: -61px;
    left: 270px;
  }
  .modal-list-machine-idle-web{
    width: 24%;
    z-index: 999;
    position: absolute;
    top: -61px;
  }

  .modal-list-machines-mobile,
  .modal-list-operations-mobile,
  .modal-list-machine-idle-mobile {
    display: none;
  }
  .map-container .map-search{
    z-index: 0 !important; 
  }
}

@media (min-width: 200px) and (max-width: 1024px) {
  .modal-list-machines-web {
    display: none;
  }
  .x_panel-mobile {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 17px;
    display: inline-block;
    background: #fff;
    border: 1px solid #e6e9ed;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    opacity: 1;
    transition: all 0.2s ease;
  }

  .x_panel-operations-mobile {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 17px;
    display: inline-block;
    background: #fff;
    border: 1px solid #e6e9ed;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    opacity: 1;
    transition: all 0.2s ease;
    
  }

  .x_panel-idle-mobile{
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 17px;
    display: inline-block;
    background: #fff;
    border: 1px solid #e6e9ed;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    opacity: 1;
    transition: all 0.2s ease;
  }
}

.shimmer-map-container {
  width: 100%;
  height: 100%;
  min-height: 440px;
  position: relative;
  border: 1px solid #e6e9ed;
  animation: fullView 0.5s forwards linear;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}
.animate {
  animation: shimmer 2s infinite;
  background: linear-gradient(to right, #f8f5f5 4%, #fff 25%, #f8f5f5 36%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
@media (max-width: 991px) {
  .nav-md .container.body .right_col,
  .nav-md .container.body .top_nav {
    width: 100%;
    margin: 0;
  }
  .nav-md .container.body .col-md-3.left_col {
    display: none;
  }
  .nav-md .container.body .right_col {
    width: 100%;
    padding-right: 15px;
  }

  .navbar-nav .open .dropdown-menu {
    top: 56px;
  }
  .map-container {
    height: 85vh !important;
  }
  .shimmer-map-container {

    height: 85vh;
  
  }
  
}
/* input[type=text] {
  padding:10px;
  border:0;
  box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
  border-radius:4px;
}
input[type=email] {
  padding:10px;
  border:0;
  box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
  border-radius:4px;
}
input[type=number] {
  padding:10px;
  border:0;
  box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
  border-radius:4px;
}

input[type=password] {
  padding:10px;
  border:0;
  box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
  border-radius:4px;
}


select {
  width: 100%;
  padding:10px;
  border-radius:10px !important;
  box-shadow:0 0 15px 4px rgba(0,0,0,0.06) !important;

} */

.avatar-user {
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 50%;
}
.labels {
  text-align: left !important;
}
.logo-agri {
  width: 100%;
}
.logo-mini {
  width: 75%;
}
.form-center {
  margin-top: 100px;
}
.pass-strength {
  font-size: 12px !important;
}
.poupup-confirm-email {
  overflow: auto;
  position: absolute;
  width: 50%;
  height: auto;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  background-color: #ffffff;
  transform: translate(-50%, -50%);
}
.poupup-confirm-email .checked-gif {
  width: 250px;
  height: 200px;
  justify-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 200px) and (max-width: 500px) {
  .center {
    margin-top: 15px !important;
  }
  .form-center {
    padding: 10px 0px 0px 10px;
    margin-top: 40px;
  }
}

.icon {
    font-size: 1.5em;
}

.image {
    height: 85% !important;
}

.site_title {
    text-overflow: ellipsis !important;
    font-weight: 400 !important;
    font-size: 22px !important;
    width: 100% !important;
    line-height: 59px !important;
    display: block !important;
    height: 55px !important;
    padding-left: 10px !important;
    color: rgb(236, 240, 241) !important;
    overflow: hidden !important;
}

.span {
    display: inline-block;
    width: 0.3vw
}

.img-circle {
    width: 57px !important;
    height: 57px !important;
}

.MuiOutlinedInput-input {
    padding: 12px 12px !important
}

.nav-sm ul.nav.child_menu {
    left: 97% !important;
}
.initial-loading {
    width: 100%;
    height: 100%;
    padding-top: 10%;
    text-align: center;
}
.initial-loading img{ 
    vertical-align: middle;
    text-align: center;
}
.logo-loading {
    height: 150px;
}

.list_farms{
    min-height: 87px !important;
    max-height: 457px !important;
    overflow: scroll !important;     
    overflow-x: hidden !important;
}

body {
  background: #fff;
}
.no-float {
  float: none;
}
.map-container {
  width: 100%;
  height: 100%;
  min-height: 440px;
  position: relative;
}
.map-container .map-search {
  z-index: 99999;
  position: absolute;
  top: 10px;
  right: 49px;
  max-width: 300px;
}
.map-container .map-search .btn-search {
  height: 40px;
  width: 100%;
  border-right: 1px solid #dddddd;
  font-size: 22px;
  background-color: #fff;
  border: 0;
  color: #000000;
  margin: 0;
  border-radius: 0;
}
.map-container .map-search .btn-search.active:hover {
  background: #fff;
}
.map-container .map-search .btn-search:hover {
  background: #dddddd;
}
.map-container .map-search .container-search .list-group-item:first-child {
  text-align: center;
}
.map-container .map-search .container-search .list-group-item .fa-undo {
  margin-right: 5px;
}
.map-container .map-search .container-search .list-group-item h5 > strong {
  color: #000000;
}
.map-container .map-search .minimize-button {
  width: 50px;
  height: 40px;
}
.map-container .map-search .container-search {
  background: #fff;
  margin-top: -3px;
  max-height: 395px;
  overflow: scroll;
  border: 1px solid #dddddd;
  padding: 10px;
}
.map-container .map-search .container-search ul li {
  cursor: pointer;
}

.map-container .map-search .container-search ul {
  margin-top: 10px;
}

.d-inline {
  display: inline;
}
.ico-alert {
  font-size: 17px;
  margin-right: 8px;
}

.inline-block {
  display: inline-block;
}
.bg-success {
  background: #2daf65 !important;
}
.bg-warning {
  background: #f1f14f !important;
}
.bg-finished {
  background: #4d4b4b !important;
}
.mt-5 {
  margin-top: 5px;
}
.operation_item_list {
  color: #7b8ea2;
  float: left;
  width: 100%;
}
.operation_item_list:hover {
  color: #a1b6cc;
}
.table_operation_list td,
th {
  padding: 8px 2px 10px 0 !important;
}
.table_operation_list .indicator-collumn {
  padding-top: 14px !important;
}

.w-90 {
  width: 90px;
}
.mr-30 {
  margin-right: 30px;
}

.alert.fade {
  opacity: 1 !important;
}

.operation_register_content .MuiInputBase-root:before,
.operation_register_content .MuiInput-underline:after {
  content: none !important;
}

@media only screen and (max-width: 480px) {
  .table_operation_list .status-indicator {
    margin-left: 40px;
    margin-top: 8px;
  }
  .table_operation_list th {
    text-align: center;
  }
  .operation-detail-container .col-filter {
    width: 100% !important;
    padding: 0 !important;
    margin-top: 5px;
  }
  .operation-detail-container .op-search .form-control {
    width: 100%;
    line-height: 40px;
    height: 40px;
  }
}

.mt-0 {
  margin-top: 0;
}

/* Operation Detail */
.operation-detail-container .no-padding {
  padding: 0;
}
.operation-detail-container .flex-box {
  display: inline-flex;
}

.operation-detail-container .PrivateNotchedOutline-root-69 {
  border-radius: 0 !important;
  top: -7px !important;
}
.operation-detail-container .op-search .lh-25 {
  line-height: 25px;
}

.operation-detail-container .tooltip-dinamic-filters {
  width: 347px;
  margin-right: 10px;
}
.operation-detail-container .w-40 {
  width: 40px;
}
.operation-detail-container .col-filter {
  width: 29%;
  padding: 0;
}
.operation-detail-container .op-search .no-padding-right {
  padding-right: 0;
}
.operation-detail-container .op-search .no-padding-left {
  padding-left: 0;
}

.operation-detail-container .op-search .form-control {
  height: 40px;
}

.btn-default-export {
  position: fixed;
  color: white !important;
  right: 35px;
  top: 125px;
  border-radius: 500px !important;
  font-size: 16px;
  z-index: 999;
  width: 50px;
  height: 50px;
  line-height: 37px;
  background: #554537 !important;
  border: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.btn-default-export:hover {
  background: #6c5847 !important;
  color: white !important;
}
.btn-default-add {
  position: fixed;
  right: 35px;
  top: 69px;
  border-radius: 500px !important;
  font-size: 16px;
  z-index: 999;
  width: 50px;
  height: 50px;
  line-height: 37px;
  background: #2aaf65;
  border: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.btn-default-add:hover {
  background: #2aaf65 !important;
}

.btn-primary {
  background: #2c995b !important;
  border: 0 !important;
}

.btn-primary:hover {
  background: #2aaf65 !important;
  border: 0 !important;
}

@media screen and (max-device-width: 640px) {
  .btn-default-add {
    bottom: 20px;
    top: auto;
    right: 10px;
  }
  .btn-default-export {
    bottom: 75px;
    top: auto;
    right: 10px;
  }
  .x_content .tab-pane svg {
    padding: 0 !important;
  }
  .select-container {
    width: 100% !important;
    float: none !important;
  }
}

.select-container {
  width: 25%;
  float: left;
  margin-right: 20px;
}

.circle-red{
  height: 10px;
  width: 10px;
  background-color: #ff1a1a;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
}
.circle-green{
  height: 10px;
  width: 10px;
  background-color: #2aaf65;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
}

.btn-export-extra {
  top: 70px;
  background-color: green !important;  
}
.btn-export-extra:hover {
  background: green !important;
  color: white !important;
}
.floating-dropdown {
  position: fixed;
  right: 35px;
  top: 69px;
  border-radius: 500px !important;
  font-size: 16px;
  z-index: 999;
  width: 50px;
  height: 50px;
  line-height: 37px;
  background: #2aaf65;
  border: 0;

  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.floating-dropdown:hover {
  background: #2aaf65 !important;
}

.btn-floating-dropdown {
  margin-top: 6px;
  margin-left: 5px;
  background-color: none;
  background: #2aaf65;
  color: white;
  height: 20px;
}

.btn-floating-dropdown:hover {
  margin-top: 6px;
  margin-left: 5px;
  background-color: none;
  background: #2aaf65;
  color: white;
  height: 20px;
}

.dropdown-floating-list {
  margin-left: -110px;
}

@media screen and (max-device-width: 640px) {
  .floating-dropdown {
    bottom: 20px;
    top: auto;
    right: 10px;
  }
  .dropdown-floating-list {
    margin-top: -143px;
    margin-left: -110px;
  }
}

.tooltip {
    position: absolute;
    display: inline-block;        
    width: 155px;
    opacity: 1 !important;
  }
  
  .tooltip .tooltiptext {
    width: 100px;
    background-color: #222;
    color: #fff;
    opacity: 0.8;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;    
    margin-left: -60px;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #222 transparent transparent transparent;
  }
.poupup-size{
    overflow: auto; 
    position: absolute;
    width: 50%;
    height: 30%;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    background-color:white;
    transform: translate(-50%, -50%);
}
    

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  

.floating-menu {
  top:90px;
  z-index: 999;
	position: fixed;
  right: 35px;
	height: 42px;
  text-align: right;
	color:white !important;
}

.floating-menu.open {margin-bottom: 30px;}

.floating-menu-item label{
	display: inline-block;
	margin-right: 8px;
	padding: 4px 10px;
	border-radius: 12px;
	background:#222;
	opacity: 0;
	transition: opacity 250ms ease-in-out;
}

.floating-menu.open .floating-menu-item label {opacity: 0.4;}

.floating-menu-icon{
	display: inline-block;
	width: 50px;
	height: 50px;
	line-height: 50px;
	vertical-align: middle;
	background: #2C995B !important;
	border-radius: 50%;
	border: none;
	box-shadow: 0 2px 4px rgba(0,0,0,.2);
  text-align: center;
  color: white;
}

.floating-menu-icon .material-icons {
	font-size: 32px;
	color: #fff;
	vertical-align: middle;
}

.btn-default-export-float{
  color: white ;
  right: 70px ;
  border-radius: 50% ;
  font-size: 12px ;
  z-index: 999 ;
  width: 32px ;
  height: 32px;
  line-height: 18px ;
  background: #554537 ;
  border: 0;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

.btn-default-export-float:hover{
  background: #5e4937 !important;
  color: white !important; 
}

.btn-default-import-float{
  position: fixed;
  color: white !important;
  right: 75px;
  top: 168px;
  border-radius: 50% !important;    
  font-size:12px;
  z-index: 999;
  width: 32px;
  height: 32px;
  background: rgb(34, 127, 0) !important;
  border: 0;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

.btn-default-import-float:hover{
  background: rgb(40, 148, 1) !important;
  color: white !important; 
}

.btn-default-add-float{
  position: fixed;
  right: 84px;
  top: 120px;
  border-radius: 50% !important;    
  font-size:10px;
  z-index: 999;
  width: 32px;
  height: 32px;
  line-height:21px;
  background: #2AAF65;
  border: 0;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

.btn-default-add-float:hover{background: #2AAF65 !important;}


@media screen and (max-device-width: 640px){
  .btn-default-add-float{
    bottom: 90px;
          top: auto;
      right: 10px;
  }
  .btn-default-export-float{
      bottom: 75px;
      top: auto;
      right: 10px;
  }
  .btn-default-import-float{
    bottom: 130px;
    top: auto;
    right: 10px
}
.floating-menu{
  bottom: 15px;
  top: auto;
  right: 10px
}

}

.map_style{
    width: 100%; 
    height: 450px;
}
.modal-size {   
    overflow: auto; 
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    background-color:white;
    transform: translate(-50%, -50%);
}
.operation-list-container .pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover{
    background-color: #3EAF5C !important;
    border-color: #3EAF5C !important;
}
.k-pdf-export .tile_pdf {
    /* display: block; */
    background-color: #3EAF5C;
    display: auto;
    width: 50% !important;
    height: auto !important;
    /* width: 100px;
    position: absolute;
    left: 0;
    height: 65px;
    border-left: 2px solid #ADB2B5;
    margin-top: 10px */
}








.btn-default-add{
    position: fixed;
    right: 35px;
    top: 69px;
    border-radius: 500px !important;    
    font-size:16px;
    z-index: 999;
    width: 50px;
    height: 50px;
    line-height:37px;
    background: #2AAF65;
    border: 0;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
  }

.btn-default-add:hover{
    background: #2AAF65 !important;
  }
  
  .btn-primary{
    background: #2C995B !important;
    color: #fff !important;
    border: 0 !important;
  }
  
  .btn-primary:hover{
    background: #2AAF65 !important;
    border: 0 !important;
  }
  
  @media screen and (max-device-width: 640px){
    .btn-default-add{
        bottom: 20px;
        top: auto;
        right: 10px;
    }
    .btn-default-export{
        bottom: 75px;
        top: auto;
        right: 10px;
    }
    .x_content .tab-pane svg{
        padding: 0 !important;
    }
  }
.row-steps {
  display: flex !important;
  flex-wrap: wrap !important;
  margin-right: -15px !important;
  margin-left: -15px !important;
}

.step-first-selects {
  width: 31%;
  padding-right: 15px;
}

.step-first-btn {
  width: 4%;
  margin-top: 14px;
}
.btn-in-row {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.span-item {
  background: #dddddd;
  height: 33px;
  text-align: center;
  border-radius: 18px;
  margin-top: 10px;
}

.span-item-small {
    background: #dddddd;
    height: 33px;
    text-align: center;
    border-radius: 18px;
    margin-top: 10px;
}


.step-second-select{
    width: 75%;
    margin-left: 10%;
    padding-right: 15px;

}
@charset "UTF-8";
.labelDate {
    text-align: right;
    font-size: 13px !important;
}

@font-face {
    font-family: "DejaVu Sans";
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
}

@font-face {
    font-family: "DejaVu Sans";
    font-weight: bold;
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "DejaVu Sans";
    font-style: italic;
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}

@font-face {
    font-family: "DejaVu Sans";
    font-weight: bold;
    font-style: italic;
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}
.MuiInputBase-input {
    font-size: 13px !important;
}

.MuiInput-input {
    font-size: 13px !important;
}

.MuiInputBase-inputAdornedEnd {
    font-size: 13px !important;
}

.MuiFormLabel-root {
    font-size: 13px !important;
}

.MuiInputLabel-root {
    font-size: 13px !important;
}

.MuiInputLabel-formControl {
    font-size: 13px !important;
}

.MuiInputLabel-animated {
    font-size: 13px !important;
}

.MuiInputLabel-shrink {    
    transform: translate(0, 1.5px) scale(1) !important;
    color: #73879C !important;
    font-weight: bold !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
    -webkit-font-smoothing: antialiased !important;
}

.MuiFormLabel-filled {
    font-size: 13px !important;
}

.mt-25{
    margin-top:25px;
}

.ml-20{
    margin-left:20px;
}

.MuiInput-underline:before{
    border-bottom: 0 !important;
}

.MuiSelect-icon{
    margin-top: 5px;
}

.hidden_title {
    display: none;
}

.k-pdf-export .hidden_title {
    display: block;
}

.form-report .MuiFormLabel-root{
    font-size: 13px !important;
    color: #73879C;
    margin-left: 5px;
    font-weight: bold;
}

.path-report-box .map-container{
    margin-bottom: 10px;
}

.path-report-box .x_title .status-indicator{
    margin-top:8px;
}

.path-report-box .btn-export-extra {
    top: 70px;
    background-color: green !important;  
}

@media (min-width: 200px) and (max-width: 500px) {
    .cardfixed {
        height: 100% !important;
    }
}

@media (min-width: 501px) and ( max-width: 1500px) {
    .cardfixed {
        height: 255px !important;
    }
} 

.text-right{
    width: 50% !important;
}

.k-grid {
    font-family: "Arial", sans-serif;
}

.clear_map {
  top: 5px;
  left: 55%;
  position: absolute;
  margin: 0 auto;
}

.collapsed-button-map {
  z-index: 999;
  position: absolute;
  top: 10px;
  right: 4px;
  /* max-width: 290px; */
  /* width: 311px; */
}

.collapsed-content {
  background: #efefef;
  color: #000;
  z-index: 999;
  position: absolute;
  top: 46px;
  right: 9px;
  /* max-width: 285px;
  width: 240px; */
  height: 386px;
  max-height: 386px;
  border-radius: 4px;
}

.table-responsive {
  max-height: 300px !important;
}

ul.bar_tabs > li a {
  color: #3a3636;
}

@media screen and (max-device-width: 640px) {
  .collapsed-button-map {
    width: 100px;
    z-index: 999;
    margin-top: -5px;
  }

  ul.bar_tabs > li {
    border: 1px solid #e6e9ed;
    color: #333 !important;
    margin-top: -17px;
    margin-left: 4px;
    background: #fff;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
  }
  /* .collapsed-content {
    z-index: 999;
    height: 375px;
    max-height: 375px;
  } */
  .clear_map {
    margin-top: -38px !important;
    margin-left: 6px;
    position: absolute;
    width: 102px !important;
  }
}

.appoiments-container li > a {
  color: #7b8ea2 !important;
}

.appoiments-container label {
  color: #7b8ea2 !important;
}
.table-row-color td a {
  color: #7b8ea2 !important;
}

.table-row-color tr > td {
  color: #7b8ea2 !important;
}

.table-row-color td a:hover {
  color: #a1b6cc !important;
}

.loading-spinner {
  margin-top: 50px;
  margin-left: 50%;
  min-height: 200px;
}
.collapse-suport {
  border: 1px solid #e6e9ed;
  border-radius: 4px;
}
.row-collpse-responsive {
  width: 100% !important;
  display: flex !important;
}

.col-collapse {
  width: 23%;
  margin-right: 18px;
  align-items: center;
}

.col-collapse-suply {
  /* width: 16%; */
  margin-right: 18px;
  align-items: center;
}
.col-6-responsive {
  margin-left: 150px;
  width: 39%;
}

.responsive-inline-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.input-collapse {
  height: 37px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: 0;
}
.tab-children {
  float: left;
  margin-bottom: -1px;
  position: relative;
  display: block;
}

.content-tabs > a {
  padding: 13px 28px 2px;

  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid #a3bbbe;
  border-radius: 4px 4px 0 0;
  border-bottom-color: transparent;
  color: #a3bbbe;
  opacity: 0.2s;
}

.content-tabs > a:hover {
  color: #8791a2;
}

.title-appointments {
  color: #8791a2;
}

.image{
    width: 100%;
    display:block;
}
.mask{
    width: 100% !important;
}
.box{   
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    font-size: 17px;
    background: rgba(0, 0, 0, 0.35);
    border: none !important;
    margin: 125px 0 0 0 !important;
}
.ico{
    color: #fff !important;
}
.map {
    height: 400px;
    width: 100%;
}
.clear_area{
    margin-top: 363px;
    margin-left: 2px;
    position: absolute;
}
.point{color:#73879C;}
.point a:hover{color:#73879C;}

@media (min-width: 200px) and (max-width: 500px) {
    .min-card-fixed {
        height: 100% !important;
    }
}

@media (min-width: 501px) and ( max-width: 1500px) {
    .min-card-fixed {
        height: 255px !important;
    }
} 
@media  (min-width: 501px) and (  max-width: 2048px) {
    .col-md-12h{width: 100%;}
    .months_list{display: none;margin-top:-22px}
    .cardfixed{ height: 338px !important;}
}
.fixed-height-140{height: 200px;}
.mobile{margin-top:-22px;}
.select-year-box{margin-top:-22px;}
.rolatable{height: 100% !important;}
.bar_tabs {padding-left: 0px !important;}

@media (min-width: 200px) and (max-width: 500px) {
    .months{display: none !important ;}
    .cardfixed{ height: 100% !important;}
    .col-md-12h{width: 100%;}
    .months_list{margin-top:-22px}
}


@media  (min-width: 501px) and (  max-width: 1500px) {
    .cardfix{ height: 250px !important;}
}

@media (min-width: 200px) and (max-width: 500px) {
    .cardfix{ height: 100% !important;}
}


/* .map-container{
  position: relative !important;  
} */

/* .map-container img[src*="/traccar/"] {
  position: absolute !important;
  top: 25px !important;  
}

.map-container div+div, .map-container div+div+div, .map-container div+div+div+div {
  overflow: visible !important;  
} */
/* 
div.gm-style > div {
  color: #FFF;
} */
