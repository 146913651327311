.map_style{
    width: 100%; 
    height: 450px;
}
.modal-size {   
    overflow: auto; 
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    background-color:white;
    transform: translate(-50%, -50%);
}
.operation-list-container .pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover{
    background-color: #3EAF5C !important;
    border-color: #3EAF5C !important;
}
.k-pdf-export .tile_pdf {
    /* display: block; */
    background-color: #3EAF5C;
    display: auto;
    width: 50% !important;
    height: auto !important;
    /* width: 100px;
    position: absolute;
    left: 0;
    height: 65px;
    border-left: 2px solid #ADB2B5;
    margin-top: 10px */
}








.btn-default-add{
    position: fixed;
    right: 35px;
    top: 69px;
    border-radius: 500px !important;    
    font-size:16px;
    z-index: 999;
    width: 50px;
    height: 50px;
    line-height:37px;
    background: #2AAF65;
    border: 0;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
  }

.btn-default-add:hover{
    background: #2AAF65 !important;
  }
  
  .btn-primary{
    background: #2C995B !important;
    color: #fff !important;
    border: 0 !important;
  }
  
  .btn-primary:hover{
    background: #2AAF65 !important;
    border: 0 !important;
  }
  
  @media screen and (max-device-width: 640px){
    .btn-default-add{
        bottom: 20px;
        top: auto;
        right: 10px;
    }
    .btn-default-export{
        bottom: 75px;
        top: auto;
        right: 10px;
    }
    .x_content .tab-pane svg{
        padding: 0 !important;
    }
  }