body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  scrollbar-color: #e2e2e2 #2c995b;
}

::-webkit-scrollbar {
  width: 10px;
}

::selection {
  color: #fff;
  background: #3eaf5c !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.operation-list-container .pull-left a {
  color: #c5c7cb !important;
}

.operation-list-container .pull-left a:hover {
  color: #23527c !important;
}

.operation-list-container td a {
  color: #7b8ea2 !important;
}

.operation-list-container td a:hover {
  color: #a1b6cc !important;
}

.operation-list-container td,
h2,
th {
  color: #7b8ea2 !important;
}
td,
th {
  color: #7b8ea2 !important;
}
.operation-detail-container {
  color: rgb(115, 135, 156);
}

.operation-detail-container h1 {
  color: rgb(115, 135, 156);
}

.operation-detail-container .pull-right {
  color: rgb(115, 135, 156);
}

#operation_update_container {
  color: rgb(115, 135, 156);
}

#menu_toggle a:hover {
  color: #ecf0f1;
}
#machine_list_container h4 {
  color: rgb(115, 135, 156);
}
#machine_list_container .btn-group a {
  color: rgb(115, 135, 156) !important;
}

#machine_list_container h4 {
  color: rgb(115, 135, 156) !important;
}

#machine_list_container {
  color: rgb(115, 135, 156);
}

#machine_list_container .btn-group a:hover {
  color: #333;
}

#machine_form_container {
  color: rgb(115, 135, 156);
}

#machine_daily_report {
  color: rgb(115, 135, 156);
}

#machine_daily_report h1 {
  color: rgb(115, 135, 156);
}

.pull-right {
  color: rgb(115, 135, 156);
}

#implement_list_container td {
  color: rgb(115, 135, 156);
}

#implement_list_container {
  color: rgb(115, 135, 156);
}

#implement_list_container .table a {
  color: rgb(115, 135, 156);
}

#implement_list_container .table a:hover {
  color: #333;
}

#implement_form_container {
  color: rgb(115, 135, 156);
}

#operator_list_container {
  color: rgb(115, 135, 156);
}

#operator_list_container h4 {
  color: rgb(115, 135, 156);
}

#operator_list_container .pull-right a {
  color: rgb(115, 135, 156) !important;
}

#operator_list_container .pull-right a:hover {
  color: rgb(115, 135, 156);
}

#operator_create_container {
  color: rgb(115, 135, 156);
}

#landplot_form_container {
  color: rgb(115, 135, 156);
}
#report_fleet_container a {
  color: rgb(115, 135, 156);
}

#report_fleet_container h2,
a {
  color: rgb(115, 135, 156);
}

.months {
  color: rgb(115, 135, 156);
}
.months:hover {
  color: #23527c;
}

#report_fleet_container {
  color: rgb(115, 135, 156);
}

#report_activity_container {
  color: rgb(115, 135, 156);
}

#report_activity_container h2,
small,
a {
  color: rgb(115, 135, 156);
}

.path-report-box {
  color: rgb(115, 135, 156);
}

.path-report-box h1 small {
  color: rgb(115, 135, 156) !important;
}

#propriedades_create_container {
  color: rgb(115, 135, 156);
}

#profile_update_container {
  color: rgb(115, 135, 156);
}

#admin_list_container {
  color: rgb(115, 135, 156);
}

#admin_list_container h4 {
  color: rgb(115, 135, 156);
}

#admin_list_container .pull-right a {
  color: rgb(115, 135, 156);
}

#admin_list_container .pull-right a:hover {
  color: rgb(115, 135, 156);
}

#profile_reset_password {
  color: rgb(115, 135, 156);
}

#admin_form_container {
  color: rgb(115, 135, 156);
}

#signup_profile_container {
  color: rgb(115, 135, 156);
}

#signup_profile_container h1 {
  color: rgb(115, 135, 156);
}

#login_container {
  color: rgb(115, 135, 156);
}

#login_container h1 {
  color: rgb(115, 135, 156);
}

#login_container a {
  color: rgb(115, 135, 156);
}

#login_container a:hover {
  color: #23527c;
}
a {
  color: #ecf0f1;
}

.profile_info h2 {
  color: #ecf0f1 !important;
}

.searchForm .operation_register_content {
  color: rgb(115, 135, 156) !important;
}

.operation_register_content {
  color: rgb(115, 135, 156) !important;
}

.btn-default {
  color: #333 !important;
  background-color: #ecf0f1;
  border-color: #ccc;
  border-top-color: rgb(204, 204, 204);
  border-right-color: rgb(204, 204, 204);
  border-bottom-color: rgb(204, 204, 204);
  border-left-color: rgb(204, 204, 204);
}
.toggle a:hover {
  color: #ecf0f1;
}

.toggle-subtitle {
  margin-left: 10px;
  font-size: 12px;
  position: absolute;
  margin-top: 3px;
}

.btnSearch {
  height: 33px;
}

.toggle-component {
  background: #dbdbdb;
  padding: 14px;
}

.glyphicon-filter i {
  color: gray;
  margin-left: 5px;
}

.glyphicon-filter {
  color: #2daf65;
  margin-left: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.operation_register_content {
  margin: 20px;
}

.operation_register_content .first-op-step-container {
  position: relative;
  top: 10px;
  margin-left: 50%;
  left: -10%;
}

.operation_register_content .no-position {
  position: unset !important;
}
.searchForm label {
  color: rgb(115, 135, 156);
}
input[type="radio"] {
  cursor: pointer;
}
.check {
  width: 17px;
  height: 17px;
  margin-right: 7px;
  position: relative;
  border-radius: 50%;
  transition: transform 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.btn-kpi-add {
  width: 100%;
  border: none;
  background: #2c995b;
  color: rgb(255, 249, 249);

  transition: 0.2s;
}
.btn-kpi-add:hover {
  width: 100%;
  border: none;
  background: #45bb78;
  color: #fff;
}
.machine-link-detail {
  list-style: none;

  text-decoration: none;

  color: #333;
}
.machine-link-detail:hover {
  color: #333;
}

.list-machines-with-link {
  min-height: 87px !important;
  max-height: 250px !important;
  overflow: scroll !important;
  overflow-x: hidden !important;
}

.list-operations-with-link {
  min-height: 87px !important;
  max-height: 250px !important;
  overflow: scroll !important;
  overflow-x: hidden !important;
}

.table-machines-idle{
  min-height: 87px !important;
  max-height: 250px !important;
  overflow: scroll !important;
  overflow-x: hidden !important;
}

@media (min-width: 1200px) {
  .modal-list-machines-web {
    position: absolute;
    top: -61px;
    right: 270px;
    width: 24%;
  }
  .modal-list-operations-web {
    width: 24%;
    z-index: 999;
    position: absolute;
    top: -61px;
    left: 270px;
  }
  .modal-list-machine-idle-web{
    width: 24%;
    z-index: 999;
    position: absolute;
    top: -61px;
  }

  .modal-list-machines-mobile,
  .modal-list-operations-mobile,
  .modal-list-machine-idle-mobile {
    display: none;
  }
  .map-container .map-search{
    z-index: 0 !important; 
  }
}

@media (min-width: 200px) and (max-width: 1024px) {
  .modal-list-machines-web {
    display: none;
  }
  .x_panel-mobile {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 17px;
    display: inline-block;
    background: #fff;
    border: 1px solid #e6e9ed;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    opacity: 1;
    transition: all 0.2s ease;
  }

  .x_panel-operations-mobile {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 17px;
    display: inline-block;
    background: #fff;
    border: 1px solid #e6e9ed;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    opacity: 1;
    transition: all 0.2s ease;
    
  }

  .x_panel-idle-mobile{
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 17px;
    display: inline-block;
    background: #fff;
    border: 1px solid #e6e9ed;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    opacity: 1;
    transition: all 0.2s ease;
  }
}

.shimmer-map-container {
  width: 100%;
  height: 100%;
  min-height: 440px;
  position: relative;
  border: 1px solid #e6e9ed;
  animation: fullView 0.5s forwards linear;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}
.animate {
  animation: shimmer 2s infinite;
  background: linear-gradient(to right, #f8f5f5 4%, #fff 25%, #f8f5f5 36%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
@media (max-width: 991px) {
  .nav-md .container.body .right_col,
  .nav-md .container.body .top_nav {
    width: 100%;
    margin: 0;
  }
  .nav-md .container.body .col-md-3.left_col {
    display: none;
  }
  .nav-md .container.body .right_col {
    width: 100%;
    padding-right: 15px;
  }

  .navbar-nav .open .dropdown-menu {
    top: 56px;
  }
  .map-container {
    height: 85vh !important;
  }
  .shimmer-map-container {

    height: 85vh;
  
  }
  
}
/* input[type=text] {
  padding:10px;
  border:0;
  box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
  border-radius:4px;
}
input[type=email] {
  padding:10px;
  border:0;
  box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
  border-radius:4px;
}
input[type=number] {
  padding:10px;
  border:0;
  box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
  border-radius:4px;
}

input[type=password] {
  padding:10px;
  border:0;
  box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
  border-radius:4px;
}


select {
  width: 100%;
  padding:10px;
  border-radius:10px !important;
  box-shadow:0 0 15px 4px rgba(0,0,0,0.06) !important;

} */
