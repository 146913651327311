.image{
    width: 100%;
    display:block;
}
.mask{
    width: 100% !important;
}
.box{   
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    font-size: 17px;
    background: rgba(0, 0, 0, 0.35);
    border: none !important;
    margin: 125px 0 0 0 !important;
}
.ico{
    color: #fff !important;
}
.map {
    height: 400px;
    width: 100%;
}
.clear_area{
    margin-top: 363px;
    margin-left: 2px;
    position: absolute;
}
.point{color:#73879C;}
.point a:hover{color:#73879C;}

@media (min-width: 200px) and (max-width: 500px) {
    .min-card-fixed {
        height: 100% !important;
    }
}

@media (min-width: 501px) and ( max-width: 1500px) {
    .min-card-fixed {
        height: 255px !important;
    }
} 