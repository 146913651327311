.floating-dropdown {
  position: fixed;
  right: 35px;
  top: 69px;
  border-radius: 500px !important;
  font-size: 16px;
  z-index: 999;
  width: 50px;
  height: 50px;
  line-height: 37px;
  background: #2aaf65;
  border: 0;

  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.floating-dropdown:hover {
  background: #2aaf65 !important;
}

.btn-floating-dropdown {
  margin-top: 6px;
  margin-left: 5px;
  background-color: none;
  background: #2aaf65;
  color: white;
  height: 20px;
}

.btn-floating-dropdown:hover {
  margin-top: 6px;
  margin-left: 5px;
  background-color: none;
  background: #2aaf65;
  color: white;
  height: 20px;
}

.dropdown-floating-list {
  margin-left: -110px;
}

@media screen and (max-device-width: 640px) {
  .floating-dropdown {
    bottom: 20px;
    top: auto;
    right: 10px;
  }
  .dropdown-floating-list {
    margin-top: -143px;
    margin-left: -110px;
  }
}
