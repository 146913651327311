@charset "UTF-8";
.labelDate {
    text-align: right;
    font-size: 13px !important;
}

@font-face {
    font-family: "DejaVu Sans";
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
}

@font-face {
    font-family: "DejaVu Sans";
    font-weight: bold;
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "DejaVu Sans";
    font-style: italic;
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}

@font-face {
    font-family: "DejaVu Sans";
    font-weight: bold;
    font-style: italic;
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}
.MuiInputBase-input {
    font-size: 13px !important;
}

.MuiInput-input {
    font-size: 13px !important;
}

.MuiInputBase-inputAdornedEnd {
    font-size: 13px !important;
}

.MuiFormLabel-root {
    font-size: 13px !important;
}

.MuiInputLabel-root {
    font-size: 13px !important;
}

.MuiInputLabel-formControl {
    font-size: 13px !important;
}

.MuiInputLabel-animated {
    font-size: 13px !important;
}

.MuiInputLabel-shrink {    
    transform: translate(0, 1.5px) scale(1) !important;
    color: #73879C !important;
    font-weight: bold !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
    -webkit-font-smoothing: antialiased !important;
}

.MuiFormLabel-filled {
    font-size: 13px !important;
}

.mt-25{
    margin-top:25px;
}

.ml-20{
    margin-left:20px;
}

.MuiInput-underline:before{
    border-bottom: 0 !important;
}

.MuiSelect-icon{
    margin-top: 5px;
}

.hidden_title {
    display: none;
}

.k-pdf-export .hidden_title {
    display: block;
}

.form-report .MuiFormLabel-root{
    font-size: 13px !important;
    color: #73879C;
    margin-left: 5px;
    font-weight: bold;
}

.path-report-box .map-container{
    margin-bottom: 10px;
}

.path-report-box .x_title .status-indicator{
    margin-top:8px;
}

.path-report-box .btn-export-extra {
    top: 70px;
    background-color: green !important;  
}

@media (min-width: 200px) and (max-width: 500px) {
    .cardfixed {
        height: 100% !important;
    }
}

@media (min-width: 501px) and ( max-width: 1500px) {
    .cardfixed {
        height: 255px !important;
    }
} 

.text-right{
    width: 50% !important;
}

.k-grid {
    font-family: "Arial", sans-serif;
}
