@media  (min-width: 501px) and (  max-width: 2048px) {
    .col-md-12h{width: 100%;}
    .months_list{display: none;margin-top:-22px}
    .cardfixed{ height: 338px !important;}
}
.fixed-height-140{height: 200px;}
.mobile{margin-top:-22px;}
.select-year-box{margin-top:-22px;}
.rolatable{height: 100% !important;}
.bar_tabs {padding-left: 0px !important;}

@media (min-width: 200px) and (max-width: 500px) {
    .months{display: none !important ;}
    .cardfixed{ height: 100% !important;}
    .col-md-12h{width: 100%;}
    .months_list{margin-top:-22px}
}

